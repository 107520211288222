import React from "react";
import * as classes from "./HeroBox.module.scss";
import { graphql, useStaticQuery } from "gatsby";
import Services from "../Services/Services";
import HeroPopup from "../HeroPopup/HeroPopup";
const { HeroBox } = classes;
export default () => {
  const data = useStaticQuery(graphql`
    {
      home: file(name: { eq: "home" }) {
        childMarkdownRemark {
          html
        }
      }
    }
  `);

  return (
    <div className={HeroBox}>
      <div>
        <HeroPopup />
        <div
          dangerouslySetInnerHTML={{
            __html: data.home.childMarkdownRemark.html,
          }}
        />
        <div>
          <Services />
        </div>
      </div>
    </div>
  );
};
