import React from "react";
import * as classes from "./HeroPopup.module.scss";
import { StaticImage } from "gatsby-plugin-image";
const { HeroPopup, HeroPopupItem } = classes;
export default () => {

  return (
    <div className={HeroPopup}>
      <a className={HeroPopupItem} href="https://spreeplan.de/news/24-Tag-der-Architektur-Berlin">
        <StaticImage 
          layout="fullWidth" 
          breakpoints={[100, 200, 300]} 
          src="../../static/assets/Tag-der-Architektur-2024.jpg" />
      </a>
      <a className={HeroPopupItem} href="https://spreeplan.de/news/17-Nominierung-Deutscher-Nachhaltigkeitspreis">
        <StaticImage 
          layout="fullWidth" 
          breakpoints={[100, 200, 300]} 
          src="../../static/assets/deutscher-nachhaltigkeitspreis-dnp-spreeplan-2024-nominiert.png" />
      </a>
    </div>
  );
};
