import React from "react";
import Layout from "../components/Layout/Layout";
import HeroBox from "../components/HeroBox/HeroBox";
export default function Home() {
  return (
    <Layout index>
      <HeroBox />
    </Layout>
  );
}
